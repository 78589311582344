import { Button, Modal } from "react-bootstrap";
import Zoom from "react-medium-image-zoom";

import noImage from "../../../images/no-image.jpg";

export default function EntriesDetailsModal(props) {
  return (
    <Modal
      className="fade bd-example-modal-lg"
      size="lg"
      show={props.modalCentered}
      onHide={props.setModalCentered}
      centered
    >
      <Modal.Header>
        <Modal.Title>Details</Modal.Title>
        <Button
          onClick={() => {
            props.setModalCentered(false);
          }}
          variant=""
          className="btn-close"
        ></Button>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <div
          className="col-12" // key={ind}
        >
          <div className="property-card style-1">
            <div className={`dz-media h-full `}>
              <Zoom>
                <img alt="" src={props.viewDetails?.images?.[0] || noImage} />
              </Zoom>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
