import { useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const EntriesDetailsForm = ({
  entriesDetails,
  setEntriesDetails,
  onSubmit,
}) => {
  const [errors, setErrors] = useState({});
  const [previews, setPreviews] = useState([]);
  const nav = useNavigate();

  const REQUIRED_FIELDS = ["amount", "type", "description", "entry_date"];

  const validateField = (name, value) => {
    let fieldErrors = { ...errors };

    switch (name) {
      case "amount":
        if (!value) {
          fieldErrors[name] = "Amount is required";
        } else if (isNaN(value) || value <= 0) {
          fieldErrors[name] = "Please enter a valid positive amount";
        } else {
          delete fieldErrors[name];
        }
        break;

      case "type":
        if (!value) {
          fieldErrors[name] = "Type is required";
        } else if (!["Income", "Expense"].includes(value)) {
          fieldErrors[name] = "Type must be either 'Income' or 'Expense'";
        } else {
          delete fieldErrors[name];
        }
        break;

      case "description":
        if (!value) {
          fieldErrors[name] = "Description are required";
        } else if (value.length > 100) {
          fieldErrors[name] = "Description cannot exceed 100 characters";
        } else {
          delete fieldErrors[name];
        }
        break;

      case "entry_date":
        if (!value) {
          fieldErrors[name] = "Entry date is required";
        } else {
          delete fieldErrors[name];
        }
        break;

      default:
        break;
    }

    setErrors(fieldErrors);
    return Object.keys(fieldErrors).length === 0;
  };

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    REQUIRED_FIELDS.forEach((key) => {
      if (!entriesDetails[key]) {
        isValid = false;
        newErrors[key] = `${key.replace(/_/g, " ")} is required`;
      } else {
        if (!validateField(key, entriesDetails[key])) {
          isValid = false;
        }
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEntriesDetails((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const onDrop = (acceptedFiles) => {
    const validFiles = acceptedFiles.filter(
      (file) => file.size <= 2 * 1024 * 1024 // Limit size to 2MB
    );

    if (validFiles.length !== acceptedFiles.length) {
      toast.error("Some files were too large and not added.");
    }

    const newPreviews = validFiles.map((file) => URL.createObjectURL(file));
    setPreviews((prev) => [...prev, ...newPreviews]);

    setEntriesDetails((prev) => ({
      ...prev,
      images: [...(prev.images || []), ...validFiles],
    }));
  };

  const removeImage = (index) => {
    const newPreviews = [...previews];
    newPreviews.splice(index, 1);
    setPreviews(newPreviews);

    setEntriesDetails((prev) => {
      const newImages = [...(prev.images || [])];
      newImages.splice(index, 1);
      return { ...prev, images: newImages };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit();
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Add Entry Details</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit} noValidate>
              <div className="row">
                <div className="mb-3 col-lg-4 col-md-6">
                  <label className="form-label">
                    Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    name="entry_date"
                    value={entriesDetails?.entry_date || ""}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.date ? "is-invalid" : ""
                    }`}
                    max={new Date().toISOString().split("T")[0]}
                  />
                  {errors.date && (
                    <div className="invalid-feedback">{errors.entry_date}</div>
                  )}
                </div>
                <div className="mb-3 col-lg-4 col-md-6">
                  <label className="form-label">
                    Amount <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    value={entriesDetails?.amount || ""}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.amount ? "is-invalid" : ""
                    }`}
                    name="amount"
                    min="0"
                    step="0.01"
                    placeholder="Enter amount"
                  />
                  {errors.amount && (
                    <div className="invalid-feedback">{errors.amount}</div>
                  )}
                </div>

                <div className="mb-3 col-lg-4 col-md-6">
                  <label className="form-label">
                    Transaction Type <span className="text-danger">*</span>
                  </label>
                  <select
                    name="type"
                    value={entriesDetails?.type || ""}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.type ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">Select type</option>
                    <option value="Income">Income</option>
                    <option value="Expense">Expense</option>
                  </select>
                  {errors.type && (
                    <div className="invalid-feedback">{errors.type}</div>
                  )}
                </div>

                <div className="mb-3 col-12 col-md-6">
                  <label className="form-label">
                    Description <span className="text-danger">*</span>
                  </label>
                  <textarea
                    type="text"
                    rows={3}
                    value={entriesDetails?.description || ""}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.description ? "is-invalid" : ""
                    }`}
                    name="description"
                    placeholder="Enter description (Eg : Fuel Charges)"
                    maxLength="100"
                  />
                  {errors.description && (
                    <div className="invalid-feedback">{errors.description}</div>
                  )}
                </div>

                <div className="mb-3 col-12 col-md-6">
                  <label className="form-label">Images</label>
                  <div {...getRootProps()} className="drop-zone">
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the images here...</p>
                    ) : (
                      <p>
                        Drag 'n' drop images here, or click to select images
                      </p>
                    )}
                  </div>

                  {previews.length > 0 && (
                    <div>
                      <p>Previews:</p>
                      {previews.map((preview, index) => (
                        <div
                          key={index}
                          style={{
                            position: "relative",
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src={preview}
                            alt={`Preview ${index}`}
                            style={{ maxWidth: "150px", maxHeight: "150px" }}
                          />
                          <span
                            className="btn-close text-danger"
                            onClick={() => removeImage(index)}
                            style={{
                              fontSize: "18px",
                              position: "absolute",
                              top: 0,
                              right: 0,
                              cursor: "pointer",
                            }}
                          ></span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 pt-3">
                  <button
                    type="button"
                    className="btn btn-sm btn-danger light  me-2"
                    onClick={() => nav("/entries")}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-sm light btn-success"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntriesDetailsForm;
