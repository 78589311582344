import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { addNewEntries } from "../../../services/EntriesService";
import EntriesDetailsForm from "./EntriesDetailsForm";
import { mediaUpload } from "../../../services/MediaUploadService";

const AddNewEntries = () => {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [entriesDetails, setEntriesDetails] = useState({
    amount: "",
    type: "",
    description: "",
    entry_date: "",
    images: [],
  });

  const uploadImages = async (images) => {
    try {
      const uploadedImageLinks = await Promise.all(
        images.map(async (image) => {
          const formData = new FormData();
          formData.append("file", image);

          try {
            const response = await mediaUpload(formData);
            if (response?.data?.files?.[0]) {
              return { url: response.data.files[0] };
            } else {
              console.error("Error: No file link in response");
              toast.error("Failed to upload an image");
              return null;
            }
          } catch (error) {
            console.error("Error uploading image:", error);
            toast.error("Image upload failed");
            return null;
          }
        })
      );

      return uploadedImageLinks.filter((link) => link !== null);
    } catch (error) {
      console.error("Unexpected error in uploadImages:", error);
      toast.error("Unexpected error occurred during image uploads");
      return [];
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      const imageLinks = await uploadImages(entriesDetails.images);

      const postData = {
        amount: entriesDetails.amount,
        type: entriesDetails.type, //'Income', 'Expense'
        description: entriesDetails.description,
        entry_date: entriesDetails.entry_date,
        images: imageLinks,
      };

      console.log(postData);

      const response = await addNewEntries(postData);
      console.log(response);

      toast.success("Entries added successfully");
      nav("/entries");
    } catch (error) {
      console.log("Error submitting entry:", error);
      toast.error(error?.response?.data?.error || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <EntriesDetailsForm
      entriesDetails={entriesDetails}
      setEntriesDetails={setEntriesDetails}
      onSubmit={onSubmit}
    />
  );
};

export default AddNewEntries;
